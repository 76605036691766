import { Disclosure } from '@headlessui/react';
import { IconChevronDown, IconClose, IconMenu, IconNoteEditAlt } from 'components/icons';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { SITE_MAP } from 'globalConstants';
import { ProjectEnumType } from 'graphql/main/queries';
import { useModal } from 'hooks';
import { isEmpty, isUndefined, map } from 'lodash';
import Link from 'next/link';
import { Fragment } from 'react';
import ModalWrapper from '../../../shared/modal-wrapper';
import Authentication from './authentication';

export interface NavigationObject {
  name: string;
  pathname?: string;
  isExternal?: boolean;
  children?: RootNavigationObject[];
}

export interface RootNavigationObject extends NavigationObject {
  children?: ChildNavigationObject[];
}

export interface ChildNavigationObject extends NavigationObject {
  children?: ChildNavigationObject[];
}

const Menu = () => {
  const { currentUser } = useAuthenticationContext();
  const { showing: showingMenu, toggle: toggleMenu } = useModal();
  const navigations: RootNavigationObject[] = [
    {
      name: 'Trang chủ',
      pathname: '/',
    },
    {
      name: 'Nhà bán',
      pathname: '/nha-dat-ban',
      children: [
        {
          name: 'Bán nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
        },
        {
          name: 'Bán biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
        },
        {
          name: 'Bán cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
        },
        {
          name: 'Bán khách sạn',
          pathname: '/ban-khach-san',
        },
        {
          name: 'Bán nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
        },
        {
          name: 'Bán đất nền',
          pathname: '/ban-dat-nen',
        },
        {
          name: 'Bán căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
        },
        {
          name: 'Bán shophouse',
          pathname: '/ban-shophouse',
        },
        {
          name: 'Bán penthouse',
          pathname: '/ban-penthouse',
        },
        {
          name: 'Bán condotel',
          pathname: '/ban-condotel',
        },
      ],
    },
    {
      name: 'Nhà thuê',
      pathname: '/nha-dat-cho-thue',
      children: [
        {
          name: 'Cho thuê nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
        },
        {
          name: 'Cho thuê biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
        },
        {
          name: 'Cho thuê cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
        },
        {
          name: 'Cho thuê khách sạn',
          pathname: '/cho-thue-khach-san',
        },
        {
          name: 'Cho thuê nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
        },
        {
          name: 'Cho thuê đất nền',
          pathname: '/cho-thue-dat-nen',
        },
        {
          name: 'Cho thuê căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
        },
        {
          name: 'Cho thuê shophouse',
          pathname: '/cho-thue-shophouse',
        },
        {
          name: 'Cho thuê penthouse',
          pathname: '/cho-thue-penthouse',
        },
        {
          name: 'Cho thuê condotel',
          pathname: '/cho-thue-condotel',
        },
        {
          name: 'Cho thuê phòng trọ',
          pathname: '/cho-thue-phong-tro',
        },
      ],
    },
    {
      name: 'Dự án',
      pathname: `/${SITE_MAP.PROJECT.INDEX}`,
      children: [
        {
          name: 'Dự án phức hợp',
          pathname: `/du-an?projectType=${ProjectEnumType.complex}`,
        },
        {
          name: 'Dự án đất nền',
          pathname: `/du-an?projectType=${ProjectEnumType.landProject}`,
        },
        {
          name: 'Dự án khu công nghiệp',
          pathname: `/du-an?projectType=${ProjectEnumType.industrialArea}`,
        },
        {
          name: 'Dự án cao ốc văn phòng',
          pathname: `/du-an?projectType=${ProjectEnumType.buildingProject}`,
        },
        {
          name: 'Dự án trung tâm thương mại',
          pathname: `/du-an?projectType=${ProjectEnumType.shoppingMall}`,
        },
        {
          name: 'Dự án nhà ở xã hội',
          pathname: `/du-an?projectType=${ProjectEnumType.socialHouse}`,
        },
      ],
    },
    {
      name: 'Bản đồ',
      pathname: `/${SITE_MAP.MAP.INDEX}`,
    },
    {
      name: 'Định giá',
      pathname: `/${SITE_MAP.VALUATION.INDEX}`,
    },
    {
      name: 'Khám phá',
      pathname: `/${SITE_MAP.DISCOVERY.INDEX}`,
    },
    {
      name: 'TMĐT',
      pathname: `/${SITE_MAP.E_COMMERCE.INDEX}`,
      isExternal: true,
    },
    {
      name: 'Mạng xã hội',
      pathname: `/${SITE_MAP.SOCIAL_NETWORK.INDEX}/${SITE_MAP.SOCIAL_NETWORK.REAL_ESTATE_POST.INDEX}`,
      isExternal: true,
    },
    {
      name: 'Tin tức',
      pathname: `/${SITE_MAP.BLOG.INDEX}`,
      isExternal: true,
      children: [
        {
          name: 'Kiến thức bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/kien-thuc-bat-dong-san`,
          children: [
            {
              name: 'Các loại bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/cac-loai-bat-dong-san`,
            },
            {
              name: 'So sánh các loại bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/so-sanh-cac-loai-bat-dong-san`,
            },
          ],
        },
        {
          name: 'Thông tin thị trường',
          pathname: `/${SITE_MAP.BLOG.INDEX}/thong-tin-thi-truong`,
          children: [
            {
              name: 'Tin tức BĐS',
              pathname: `/${SITE_MAP.BLOG.INDEX}/tin-tuc-bds`,
            },
            {
              name: 'Proptech Việt Nam',
              pathname: `/${SITE_MAP.BLOG.INDEX}/proptech-viet-nam`,
            },
          ],
        },
        {
          name: 'Phong thủy nhà ở',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-nha-o`,
          children: [
            {
              name: 'Phong thủy phòng ngủ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-ngu`,
            },
            {
              name: 'Phong thủy phòng khách',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-khach`,
            },
            {
              name: 'Phong thủy phòng tắm',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-tam`,
            },
            {
              name: 'Phong thủy phòng bếp',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-bep`,
            },
            {
              name: 'Phong thủy cửa chính, sân vườn',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-cua-chinh-san-vuon`,
            },
          ],
        },
        {
          name: 'Phong thủy nhà đất',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-nha-dat`,
          children: [
            {
              name: 'Phong thủy vị trí',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-vi-tri`,
            },
            {
              name: 'Phong thủy hình dạng đất',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-hinh-dang-dat`,
            },
          ],
        },
        {
          name: 'Tư vấn đầu tư bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/tu-van-dau-tu-bat-dong-san`,
          children: [
            {
              name: 'So sánh đầu tư BĐS và các kênh khác',
              pathname: `/${SITE_MAP.BLOG.INDEX}/so-sanh-dau-tu-bds-va-cac-kenh-khac`,
            },
            {
              name: 'Vị trí bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/vi-tri-bat-dong-san`,
            },
            {
              name: 'Loại hình BĐS đầu tư',
              pathname: `/${SITE_MAP.BLOG.INDEX}/loai-hinh-bds-dau-tu`,
            },
          ],
        },
        {
          name: 'Phong thủy theo tuổi',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-theo-tuoi`,
          children: [
            {
              name: 'Tý',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ty`,
            },
            {
              name: 'Sửu',
              pathname: `/${SITE_MAP.BLOG.INDEX}/suu`,
            },
            {
              name: 'Dần',
              pathname: `/${SITE_MAP.BLOG.INDEX}/dan`,
            },
            {
              name: 'Mão',
              pathname: `/${SITE_MAP.BLOG.INDEX}/mao`,
            },
            {
              name: 'Thìn',
              pathname: `/${SITE_MAP.BLOG.INDEX}/thin`,
            },
            {
              name: 'Tỵ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ty-1`,
            },
            {
              name: 'Ngọ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ngo`,
            },
            {
              name: 'Mùi',
              pathname: `/${SITE_MAP.BLOG.INDEX}/mui`,
            },
            {
              name: 'Thân',
              pathname: `/${SITE_MAP.BLOG.INDEX}/than`,
            },
            {
              name: 'Dậu',
              pathname: `/${SITE_MAP.BLOG.INDEX}/dau`,
            },
            {
              name: 'Tuất',
              pathname: `/${SITE_MAP.BLOG.INDEX}/tuat`,
            },
            {
              name: 'Hợi',
              pathname: `/${SITE_MAP.BLOG.INDEX}/hoi`,
            },
          ],
        },
        {
          name: 'Vay vốn mua bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/vay-von-mua-bat-dong-san`,
        },
        {
          name: 'Thủ tục pháp lý bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/thu-tuc-phap-ly-bat-dong-san`,
          children: [
            {
              name: 'Kiểm tra quy hoạch',
              pathname: `/${SITE_MAP.BLOG.INDEX}/kiem-tra-quy-hoach`,
            },
            {
              name: 'Lưu ý khi mua bán BĐS',
              pathname: `/${SITE_MAP.BLOG.INDEX}/luu-y-khi-mua-ban-bds`,
            },
          ],
        },
      ],
    },
    {
      name: 'Dịch vụ',
      children: [
        {
          name: 'Văn phòng công chứng',
          pathname: `/${SITE_MAP.NOTARIZATION.INDEX}`,
          isExternal: true,
        },
        {
          name: 'Văn phòng đăng ký đất đai',
          pathname: `/${SITE_MAP.OFFICE_REGISTRATION.INDEX}`,
          isExternal: true,
        },
        {
          name: 'Vay tín dụng, mở thẻ, tài khoản ngân hàng',
          pathname: `/${SITE_MAP.BANK_LOAN.INDEX}`,
          isExternal: true,
        },
      ],
    },
  ];

  const toggleModal = () => {
    toggleMenu();
  };

  const renderMenuItems = (navigations: (RootNavigationObject | ChildNavigationObject)[]) => (
    <ul className='divide-y pl-[12px]'>
      {map(navigations, (navigation, navigationIndex) =>
        isEmpty(navigation.children) ? (
          <li key={`menu-navigation-${navigationIndex}`}>
            {navigation.pathname ? (
              <Link
                href={navigation.pathname}
                {...(navigation.isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                className='block py-[24px] text-[16px] leading-[20px] active:font-[600] active:text-primary'
              >
                {navigation.name}
              </Link>
            ) : (
              <span className='block py-[24px] text-[16px] leading-[20px] active:font-[600] active:text-primary'>
                {navigation.name}
              </span>
            )}
          </li>
        ) : (
          <li key={`menu-navigation-${navigationIndex}`}>
            <Disclosure>
              {({ open }) => (
                <Fragment>
                  <Disclosure.Button
                    className={`flex w-full items-center py-[24px] ${
                      open ? 'font-[600] text-primary' : 'text-text'
                    }`}
                  >
                    <span className='text-left text-[16px] leading-[20px]'>{navigation?.name}</span>
                    <IconChevronDown
                      className={`ml-[3px] min-h-[20px] min-w-[20px] ${
                        open ? 'rotate-180' : ''
                      } duration-[200ms]`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {isEmpty(navigation.children) || isUndefined(navigation.children)
                      ? null
                      : renderMenuItems(navigation.children)}
                  </Disclosure.Panel>
                </Fragment>
              )}
            </Disclosure>
          </li>
        ),
      )}
    </ul>
  );

  return (
    <div id='menu' className='flex space-x-[20px]'>
      {/* <button type='button' className='relative'>
        <AiOutlineBell className='h-[27px] w-[27px]' />
        <span className='absolute top-[-2px] right-[-2px] flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full border border-paper bg-badge text-[10px] leading-[13px] text-paper'>
          5
        </span>
      </button> */}
      <button type='button' onClick={toggleModal}>
        <IconMenu className='h-[24px] w-[24px]' />
      </button>
      <ModalWrapper isClickAway position='right' showing={showingMenu} onToggle={toggleModal}>
        <div
          className='flex h-full w-[276px] flex-col bg-paper'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='flex justify-between px-[16px] py-[24px]'>
            <Link href='/'>
              <div className='h-[32px] w-[28px] bg-[url("/favicon.svg")] bg-cover bg-center bg-no-repeat' />
            </Link>
            <button className='p-[4px]' onClick={toggleModal}>
              <IconClose className='min-h-[24px] min-w-[24px] text-placeholder' />
            </button>
          </div>
          <div className='flex-1 space-y-[24px] overflow-y-auto pl-[12px] pr-[24px]'>
            <Authentication />
            <div className='pl-[12px]'>
              <Link
                href={`/${SITE_MAP.POST_REAL_ESTATE.INDEX}`}
                className={`flex h-[40px] w-full items-center justify-center space-x-[8px] rounded-[8px] ${
                  currentUser
                    ? 'bg-primary text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                    : 'broder-stroke border'
                }`}
              >
                <IconNoteEditAlt className='min-h-[24px] min-w-[24px]' />
                <span className='font-[600]'>Đăng tin</span>
              </Link>
            </div>
            {renderMenuItems(navigations)}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default Menu;
