import { isEmpty, isEqual } from 'lodash';
import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props extends PropsWithChildren {
  isClickAway?: boolean;
  showing?: boolean;
  position?: 'left' | 'right' | 'top' | 'bottom' | 'center';
  onToggle?: () => void;
}

const ModalWrapper = ({ isClickAway, showing, position, children, onToggle }: Props) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const isPositionLeft = isEqual(position, 'left');
  const isPositionRight = isEqual(position, 'right');
  const isPositionTop = isEqual(position, 'top');
  const isPositionBottom = isEqual(position, 'bottom');
  const isPositionCenter = isEqual(position, 'center');

  const clickAway = () => {
    onToggle?.();
  };

  useEffect(() => {
    setIsBrowser(true);
  }, []);
  useEffect(() => {
    if (!isEmpty(document.getElementById(`modal-wrapper`)?.children)) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return isBrowser && showing
    ? createPortal(
        <div
          className={`fixed bottom-0 left-0 right-0 top-0 z-[100] flex bg-[#000000]/[.3] ${
            isPositionCenter ? 'animate-[fade_200ms_linear]' : ''
          } ${
            isPositionLeft
              ? 'items-center'
              : isPositionRight
              ? 'items-center justify-end'
              : isPositionTop
              ? 'items-start justify-center'
              : isPositionBottom
              ? 'items-end justify-center'
              : 'items-center justify-center'
          }`}
          onClick={isClickAway ? clickAway : undefined}
        >
          <div
            className={`${
              isPositionLeft
                ? 'h-full animate-[collapse-left_200ms_linear]'
                : isPositionRight
                ? 'h-full animate-[collapse-right_200ms_linear]'
                : isPositionTop
                ? 'w-full animate-[collapse-top_200ms_linear]'
                : isPositionBottom
                ? 'w-full animate-[collapse-bottom_200ms_linear]'
                : ''
            }`}
          >
            {children}
          </div>
        </div>,
        document.querySelector('#modal-wrapper')!,
      )
    : null;
};

export default ModalWrapper;
