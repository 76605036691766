import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { SITE_MAP } from 'globalConstants';
import { FileWithFullUrls } from 'graphql/main/queries';
import { useTranslation } from 'hooks';
import { isString } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';

const Authentication = () => {
  const { asPath, push } = useRouter();
  const translation = useTranslation();
  const { currentUser } = useAuthenticationContext();
  const { avatar, fullName, type } = currentUser ?? {};

  const signIn = () => {
    push({
      pathname: `/${SITE_MAP.SIGN_IN.INDEX}`,
      query: queryString.stringify({ redirect: asPath }, { encode: false }),
    });
  };

  return currentUser ? (
    <Link
      href={`/${SITE_MAP.MY_PROFILE.INDEX}`}
      className='flex items-center space-x-[12px] pl-[12px]'
    >
      <div className='h-[48px] min-w-[48px] max-w-[48px] overflow-hidden rounded-full'>
        <img
          src={(avatar as FileWithFullUrls)?.originalUrl ?? '/images/default-avatar.svg'}
          alt={fullName}
          loading='lazy'
          className='h-full w-full object-cover'
        />
      </div>
      <div className='flex flex-col items-start space-y-[8px]'>
        {isString(fullName) && <span className='font-[600] line-clamp-1'>{fullName}</span>}
        {isString(type) && (
          <div className='flex h-[23px] items-center justify-center rounded-[16px] bg-[#fb923c] px-[12px]'>
            <span className='text-[12px] leading-[15px] text-paper line-clamp-1'>
              {(translation.major.b2cUserTypes as any)[type]}
            </span>
          </div>
        )}
      </div>
    </Link>
  ) : (
    <div className='flex space-x-[16px] pl-[12px]'>
      <button
        type='button'
        className='flex h-[40px] w-full items-center justify-center rounded-[8px] border border-stroke'
        onClick={signIn}
      >
        <span className='font-[600]'>Đăng nhập</span>
      </button>
      <Link
        href={`/${SITE_MAP.SIGN_UP.INDEX}`}
        className='flex h-[40px] w-full items-center justify-center rounded-[8px] bg-primary transition duration-[200ms] ease-in-out hover:bg-primary-light'
      >
        <span className='font-[600] text-paper'>Đăng ký</span>
      </Link>
    </div>
  );
};

export default Authentication;
